/* eslint-disable camelcase */
import Swal from 'sweetalert2';

function useObtenerCasilla(context, seccion) {
  const obtenerCasilla = () => {
    const {
      datos_secciones,
      datos_secciones_por_municipio,
    } = context.$store.state.elementosGenerales;
    const { eleccion } = context.$route.params;

    const datos = eleccion === 'A'
      ? datos_secciones_por_municipio
      : datos_secciones.distritos;

    const seccionParseada = parseInt(seccion, 10);

    // eslint-disable-next-line max-len
    const distrito = datos.filter((localidad) => localidad.datos_secciones.find((s) => {
      const seccionInternaParseada = parseInt(s.seccion_id, 10);
      return seccionInternaParseada === seccionParseada;
    }))[0];

    if (!distrito) {
      Swal.fire({
        html: `
          <p>La sección que intenta buscar no se encuentra dentro de la lista de secciones en el estado, por favor intenta con otra sección.</p>
        `,
        icon: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#D2127E',
      });
      return null;
    }
    const id = distrito.id ?? distrito.municipio_id;

    context.$router.push({
      name: 'DetalleSeccion',
      params: {
        eleccion,
        distrito: id,
        seccion: seccionParseada,
      },
    });

    return null;
  };

  return {
    obtenerCasilla,
  };
}

export default useObtenerCasilla;
