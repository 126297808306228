<template>
  <div class="compartir text-center">
    <transition name="fade">
      <div v-if="banderaOpciones">
        <div class="facebook mb-2">
          <a :href="facebookLink" target="_blank" rel="noopener noreferrer">
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
        </div>
        <div class="twitter mb-2">
          <a :href="twitterLink" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/twitter.png" alt="Twitter">
          </a>
        </div>
        <div class="whatsapp mb-2">
          <a
            :href="whatsappLink"
            data-action="share/whatsapp/share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/whatsapplogo.png" alt="Whatasapp">
          </a>
        </div>
      </div>
    </transition>
    <div @click="mostrarOpciones">
      <div class="icono" :class="{ 'opaco': banderaOpciones }">
        <font-awesome-icon icon="share-alt" />
      </div>
      <p class="mb-0" :class="{ 'opaco': banderaOpciones }">
        <b>
          Compartir
        </b>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Compartir',
  data() {
    return {
      banderaOpciones: false,
    };
  },
  methods: {
    mostrarOpciones() {
      this.banderaOpciones = !this.banderaOpciones;
    },
  },
  computed: {
    twitterLink() {
      return `https://www.twitter.com/intent/tweet?text=Resultados Preliminares 2021&url=${window.location}/%23${this.$router.currentRoute.path}`;
    },
    whatsappLink() {
      return `https://api.whatsapp.com/send?text=Resultados Preliminares 2021 ${window.location + this.$route.path}`;
    },
    facebookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${window.location}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.compartir {
  position: fixed;
  bottom: 0;
  right: 15px;
  background-color: #FFF;
  z-index: 99;
  padding: 5px;
  box-shadow: 0px 0px 5px #ccc;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  .icono {
    background-color: #34344e;
    padding: 10px;
    border-radius: 50%;
    color: #FFF;
    border: 3px solid #d3097f;
    max-width: 50px;
    margin: 0 auto;

    svg {
      font-size: 18px;
    }
  }

  .opaco {
    opacity: .5;
  }

  .facebook > a {
    color: #3b5998;

    svg {
      font-size: 2.5em;
    }
  }
  .twitter > a {
    color: #1DA1F2;

    img {
      max-width: 2.5em;
    }
  }
  .whatsapp > a{
    color: green;
    img {
      max-width: 2.5em;
    }
  }

  &:hover {
    cursor: pointer;
    transition: all ease .2s;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
