<template>
  <b-modal
    id="AvisoPrivacidadMovilModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Aviso de privacidad"
    hide-footer
    header-bg-variant="info"
  >
    <div class="my-5">
      <p class="text-center mb-5">
        El uso de datos que nos proporciones es exclusivo para visualizar
        el detalle de la votación de tu casilla, no quedarán guardados en ninguna base de datos.
      </p>

      <button class="btn btn-secondary d-block mx-auto" @click="abrirMiCasilla">
        Aceptar
      </button>
    </div>
  </b-modal>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useObtenerCasilla from '@/composables/useObtenerCasilla';

export default defineComponent({
  name: 'AvisoPrivacidadMovilModal',
  props: {
    escritorio: {
      type: Boolean,
      default: () => false,
    },
    seccion: {
      default: () => null,
    },
  },
  setup(props, { root }) {
    const abrirMiCasilla = () => {
      if (props.escritorio) {
        root.$bvModal.hide('AvisoPrivacidadMovilModal');
        useObtenerCasilla(root, props.seccion)
          .obtenerCasilla();
        return null;
      }

      root.$store.commit('setearEstado', {
        propiedad: 'mostrarMiCasilla',
        payload: true,
      });
      root.$bvModal.hide('AvisoPrivacidadMovilModal');
      return null;
    };

    return {
      abrirMiCasilla,
    };
  },
});
</script>
