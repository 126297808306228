<template>
  <div class="componente-oscuro">
    <div class="movil mx-auto">
      <p class="font-weight-bold">
        Tema:
      </p>

      <div class="grilla">
        <div class="contenedor-boton">
          <label>
            Claro
          </label>
          <button
            class="btn btn-primary boton-claro"
            @click="eventoModoOscuro(false)"
          >
            <font-awesome-icon icon="sun" />
          </button>
        </div>
        <div class="contenedor-boton">
          <label>
            Oscuro
          </label>
          <button
            class="btn btn-primary boton-oscuro"
            @click="eventoModoOscuro(true)"
          >
            <font-awesome-icon icon="moon" />
          </button>
        </div>
      </div>
    </div>
    <div class="escritorio">
      <span>Claro</span>
      <b-form-checkbox switch size="lg" v-model="esOscuro" />
      <span>Oscuro</span>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'ModoOscuro',
  data() {
    return {
      esOscuro: false,
    };
  },
  created() {
    if (!Cookies.get('oscuro')) {
      Cookies.set('oscuro', null);
    }

    /* if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      Cookies.set('oscuro', true);
    } else {
      Cookies.set('oscuro', false);
    } */

    if (Cookies.get('oscuro') === 'true') {
      this.esOscuro = true;
      document.getElementsByTagName('body')[0].classList.add('modo-oscuro');
      this.$store.commit('setearEstado', { propiedad: 'esOscuro', payload: true });
    }
  },
  methods: {
    eventoModoOscuro(valor) {
      this.esOscuro = valor;
    },
    agregarModoOscuro(body) {
      body.classList.add('modo-oscuro');
      Cookies.set('oscuro', true);
      this.$store.commit('setearEstado', { propiedad: 'esOscuro', payload: true });
      return null;
    },
    quitarModoOscuro(body) {
      body.classList.remove('modo-oscuro');
      Cookies.set('oscuro', false);
      this.$store.commit('setearEstado', { propiedad: 'esOscuro', payload: false });
      return null;
    },
  },
  watch: {
    esOscuro() {
      const body = document.getElementsByTagName('body')[0];

      if (this.esOscuro) {
        this.agregarModoOscuro(body);
        return null;
      }

      this.quitarModoOscuro(body);
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.componente-oscuro {
  .movil {
    padding: 30px;
    max-width: 450px;
    color: #e0e0e0;

    .grilla {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      .contenedor-boton {
        label {
          display: block;
        }

        button.btn {
          width: 100%;
          border-radius: 30px;
          border-width: 1px;
          line-height: initial;

          svg {
            font-size: 20px;
          }
        }
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
  }
  .escritorio {
    display: none;

    @media (min-width: 992px) {
      display: grid;
      max-width: 145px;
      grid-template-columns: repeat(3, 1fr);

      span {
        margin-top: 0.15rem;
      }
    }
  }
}
</style>
